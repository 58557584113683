export var locations = [
    {
        isoCode: "AFG",
        countryName: "Afghanistan"
    },
    {
        isoCode: "ALB",
        countryName: "Albania"
    },
    {
        isoCode: "DZA",
        countryName: "Algeria"
    },
    {
        isoCode: "AGO",
        countryName: "Angola"
    },
    {
        isoCode: "ATG",
        countryName: "Antigua and Barbuda"
    },
    {
        isoCode: "ARG",
        countryName: "Argentina"
    },
    {
        isoCode: "ARM",
        countryName: "Armenia"
    },
    {
        isoCode: "ABW",
        countryName: "Aruba"
    },
    {
        isoCode: "AUS",
        countryName: "Australia"
    },
    {
        isoCode: "AUT",
        countryName: "Austria"
    },
    {
        isoCode: "AZE",
        countryName: "Azerbaijan"
    },
    {
        isoCode: "BHS",
        countryName: "Bahamas, The"
    },
    {
        isoCode: "BHR",
        countryName: "Bahrain"
    },
    {
        isoCode: "BGD",
        countryName: "Bangladesh"
    },
    {
        isoCode: "BRB",
        countryName: "Barbados"
    },
    {
        isoCode: "BLR",
        countryName: "Belarus"
    },
    {
        isoCode: "BEL",
        countryName: "Belgium"
    },
    {
        isoCode: "BLZ",
        countryName: "Belize"
    },
    {
        isoCode: "BEN",
        countryName: "Benin"
    },
    {
        isoCode: "BMU",
        countryName: "Bermuda"
    },
    {
        isoCode: "BTN",
        countryName: "Bhutan"
    },
    {
        isoCode: "BOL",
        countryName: "Bolivia"
    },
    {
        isoCode: "BIH",
        countryName: "Bosnia and Herzegovina"
    },
    {
        isoCode: "BWA",
        countryName: "Botswana"
    },
    {
        isoCode: "BRA",
        countryName: "Brazil"
    },
    {
        isoCode: "BRN",
        countryName: "Brunei Darussalam"
    },
    {
        isoCode: "BGR",
        countryName: "Bulgaria"
    },
    {
        isoCode: "BFA",
        countryName: "Burkina Faso"
    },
    {
        isoCode: "BDI",
        countryName: "Burundi"
    },
    {
        isoCode: "CPV",
        countryName: "Cabo Verde"
    },
    {
        isoCode: "KHM",
        countryName: "Cambodia"
    },
    {
        isoCode: "CMR",
        countryName: "Cameroon"
    },
    {
        isoCode: "CAN",
        countryName: "Canada"
    },
    {
        isoCode: "CYM",
        countryName: "Cayman Islands"
    },
    {
        isoCode: "CAF",
        countryName: "Central African Republic"
    },
    {
        isoCode: "TCD",
        countryName: "Chad"
    },
    {
        isoCode: "CHL",
        countryName: "Chile"
    },
    {
        isoCode: "CHN",
        countryName: "China"
    },
    {
        isoCode: "COL",
        countryName: "Colombia"
    },
    {
        isoCode: "COM",
        countryName: "Comoros"
    },
    {
        isoCode: "COG",
        countryName: "Congo, Rep."
    },
    {
        isoCode: "CRI",
        countryName: "Costa Rica"
    },
    {
        isoCode: "CIV",
        countryName: "Cote d’Ivoire"
    },
    {
        isoCode: "HRV",
        countryName: "Croatia"
    },
    {
        isoCode: "CUB",
        countryName: "Cuba"
    },
    {
        isoCode: "CUW",
        countryName: "Curacao"
    },
    {
        isoCode: "CYP",
        countryName: "Cyprus"
    },
    {
        isoCode: "CZE",
        countryName: "Czech Republic"
    },
    {
        isoCode: "DNK",
        countryName: "Denmark"
    },
    {
        isoCode: "DJI",
        countryName: "Djibouti"
    },
    {
        isoCode: "DMA",
        countryName: "Dominica"
    },
    {
        isoCode: "DOM",
        countryName: "Dominican Republic"
    },
    {
        isoCode: "ECU",
        countryName: "Ecuador"
    },
    {
        isoCode: "EGY",
        countryName: "Egypt, Arab Rep."
    },
    {
        isoCode: "SLV",
        countryName: "El Salvador"
    },
    {
        isoCode: "GNQ",
        countryName: "Equatorial Guinea"
    },
    {
        isoCode: "ERI",
        countryName: "Eritrea"
    },
    {
        isoCode: "EST",
        countryName: "Estonia"
    },
    {
        isoCode: "ETH",
        countryName: "Ethiopia"
    },
    {
        isoCode: "FJI",
        countryName: "Fiji"
    },
    {
        isoCode: "FIN",
        countryName: "Finland"
    },
    {
        isoCode: "FRA",
        countryName: "France"
    },
    {
        isoCode: "GAB",
        countryName: "Gabon"
    },
    {
        isoCode: "GMB",
        countryName: "Gambia, The"
    },
    {
        isoCode: "GEO",
        countryName: "Georgia"
    },
    {
        isoCode: "DEU",
        countryName: "Germany"
    },
    {
        isoCode: "GHA",
        countryName: "Ghana"
    },
    {
        isoCode: "GRC",
        countryName: "Greece"
    },
    {
        isoCode: "GRD",
        countryName: "Grenada"
    },
    {
        isoCode: "GTM",
        countryName: "Guatemala"
    },
    {
        isoCode: "GIN",
        countryName: "Guinea"
    },
    {
        isoCode: "GNB",
        countryName: "Guinea-Bissau"
    },
    {
        isoCode: "GUY",
        countryName: "Guyana"
    },
    {
        isoCode: "HTI",
        countryName: "Haiti"
    },
    {
        isoCode: "HND",
        countryName: "Honduras"
    },
    {
        isoCode: "HKG",
        countryName: "Hong Kong SAR, China"
    },
    {
        isoCode: "HUN",
        countryName: "Hungary"
    },
    {
        isoCode: "ISL",
        countryName: "Iceland"
    },
    {
        isoCode: "IND",
        countryName: "India"
    },
    {
        isoCode: "IDN",
        countryName: "Indonesia"
    },
    {
        isoCode: "IRN",
        countryName: "Iran, Islamic Rep."
    },
    {
        isoCode: "IRQ",
        countryName: "Iraq"
    },
    {
        isoCode: "IRL",
        countryName: "Ireland"
    },
    {
        isoCode: "ISR",
        countryName: "Israel"
    },
    {
        isoCode: "ITA",
        countryName: "Italy"
    },
    {
        isoCode: "JAM",
        countryName: "Jamaica"
    },
    {
        isoCode: "JPN",
        countryName: "Japan"
    },
    {
        isoCode: "JOR",
        countryName: "Jordan"
    },
    {
        isoCode: "KAZ",
        countryName: "Kazakhstan"
    },
    {
        isoCode: "KEN",
        countryName: "Kenya"
    },
    {
        isoCode: "KIR",
        countryName: "Kiribati"
    },
    {
        isoCode: "KOR",
        countryName: "Korea, Rep."
    },
    {
        isoCode: "KWT",
        countryName: "Kuwait"
    },
    {
        isoCode: "KGZ",
        countryName: "Kyrgyz Republic"
    },
    {
        isoCode: "LAO",
        countryName: "Lao PDR"
    },
    {
        isoCode: "LVA",
        countryName: "Latvia"
    },
    {
        isoCode: "LBN",
        countryName: "Lebanon"
    },
    {
        isoCode: "LSO",
        countryName: "Lesotho"
    },
    {
        isoCode: "LBR",
        countryName: "Liberia"
    },
    {
        isoCode: "LBY",
        countryName: "Libya"
    },
    {
        isoCode: "LTU",
        countryName: "Lithuania"
    },
    {
        isoCode: "LUX",
        countryName: "Luxembourg"
    },
    {
        isoCode: "MAC",
        countryName: "Macao SAR, China"
    },
    {
        isoCode: "MKD",
        countryName: "Macedonia, FYR"
    },
    {
        isoCode: "MDG",
        countryName: "Madagascar"
    },
    {
        isoCode: "MWI",
        countryName: "Malawi"
    },
    {
        isoCode: "MYS",
        countryName: "Malaysia"
    },
    {
        isoCode: "MDV",
        countryName: "Maldives"
    },
    {
        isoCode: "MLI",
        countryName: "Mali"
    },
    {
        isoCode: "MLT",
        countryName: "Malta"
    },
    {
        isoCode: "MHL",
        countryName: "Marshall Islands"
    },
    {
        isoCode: "MRT",
        countryName: "Mauritania"
    },
    {
        isoCode: "MUS",
        countryName: "Mauritius"
    },
    {
        isoCode: "MEX",
        countryName: "Mexico"
    },
    {
        isoCode: "FSM",
        countryName: "Micronesia, Fed. Sts."
    },
    {
        isoCode: "MDA",
        countryName: "Moldova"
    },
    {
        isoCode: "MNG",
        countryName: "Mongolia"
    },
    {
        isoCode: "MNE",
        countryName: "Montenegro"
    },
    {
        isoCode: "MAR",
        countryName: "Morocco"
    },
    {
        isoCode: "MOZ",
        countryName: "Mozambique"
    },
    {
        isoCode: "MMR",
        countryName: "Myanmar"
    },
    {
        isoCode: "NAM",
        countryName: "Namibia"
    },
    {
        isoCode: "NPL",
        countryName: "Nepal"
    },
    {
        isoCode: "NLD",
        countryName: "Netherlands"
    },
    {
        isoCode: "NZL",
        countryName: "New Zealand"
    },
    {
        isoCode: "NIC",
        countryName: "Nicaragua"
    },
    {
        isoCode: "NER",
        countryName: "Niger"
    },
    {
        isoCode: "NGA",
        countryName: "Nigeria"
    },
    {
        isoCode: "NOR",
        countryName: "Norway"
    },
    {
        isoCode: "OMN",
        countryName: "Oman"
    },
    {
        isoCode: "PAK",
        countryName: "Pakistan"
    },
    {
        isoCode: "PLW",
        countryName: "Palau"
    },
    {
        isoCode: "PAN",
        countryName: "Panama"
    },
    {
        isoCode: "PNG",
        countryName: "Papua New Guinea"
    },
    {
        isoCode: "PRY",
        countryName: "Paraguay"
    },
    {
        isoCode: "PER",
        countryName: "Peru"
    },
    {
        isoCode: "PHL",
        countryName: "Philippines"
    },
    {
        isoCode: "POL",
        countryName: "Poland"
    },
    {
        isoCode: "PRT",
        countryName: "Portugal"
    },
    {
        isoCode: "PRI",
        countryName: "Puerto Rico"
    },
    {
        isoCode: "QAT",
        countryName: "Qatar"
    },
    {
        isoCode: "ROU",
        countryName: "Romania"
    },
    {
        isoCode: "RUS",
        countryName: "Russian Federation"
    },
    {
        isoCode: "RWA",
        countryName: "Rwanda"
    },
    {
        isoCode: "WSM",
        countryName: "Samoa"
    },
    {
        isoCode: "SMR",
        countryName: "San Marino"
    },
    {
        isoCode: "STP",
        countryName: "Sao Tome and Principe"
    },
    {
        isoCode: "SAU",
        countryName: "Saudi Arabia"
    },
    {
        isoCode: "SEN",
        countryName: "Senegal"
    },
    {
        isoCode: "SRB",
        countryName: "Serbia"
    },
    {
        isoCode: "SYC",
        countryName: "Seychelles"
    },
    {
        isoCode: "SLE",
        countryName: "Sierra Leone"
    },
    {
        isoCode: "SGP",
        countryName: "Singapore"
    },
    {
        isoCode: "SXM",
        countryName: "Sint Maarten (Dutch part)"
    },
    {
        isoCode: "SVK",
        countryName: "Slovak Republic"
    },
    {
        isoCode: "SVN",
        countryName: "Slovenia"
    },
    {
        isoCode: "SLB",
        countryName: "Solomon Islands"
    },
    {
        isoCode: "SOM",
        countryName: "Somalia"
    },
    {
        isoCode: "ZAF",
        countryName: "South Africa"
    },
    {
        isoCode: "ESP",
        countryName: "Spain"
    },
    {
        isoCode: "LKA",
        countryName: "Sri Lanka"
    },
    {
        isoCode: "KNA",
        countryName: "St. Kitts and Nevis"
    },
    {
        isoCode: "LCA",
        countryName: "St. Lucia"
    },
    {
        isoCode: "VCT",
        countryName: "St. Vincent and the Grenadines"
    },
    {
        isoCode: "SDN",
        countryName: "Sudan"
    },
    {
        isoCode: "SUR",
        countryName: "Suriname"
    },
    {
        isoCode: "SWZ",
        countryName: "Swaziland"
    },
    {
        isoCode: "SWE",
        countryName: "Sweden"
    },
    {
        isoCode: "CHE",
        countryName: "Switzerland"
    },
    {
        isoCode: "SYR",
        countryName: "Syrian Arab Republic"
    },
    {
        isoCode: "TWN",
        countryName: "Taiwan"
    },
    {
        isoCode: "TJK",
        countryName: "Tajikistan"
    },
    {
        isoCode: "TZA",
        countryName: "Tanzania"
    },
    {
        isoCode: "THA",
        countryName: "Thailand"
    },
    {
        isoCode: "TLS",
        countryName: "Timor-Leste"
    },
    {
        isoCode: "TGO",
        countryName: "Togo"
    },
    {
        isoCode: "TON",
        countryName: "Tonga"
    },
    {
        isoCode: "TTO",
        countryName: "Trinidad and Tobago"
    },
    {
        isoCode: "TUN",
        countryName: "Tunisia"
    },
    {
        isoCode: "TUR",
        countryName: "Turkey"
    },
    {
        isoCode: "TKM",
        countryName: "Turkmenistan"
    },
    {
        isoCode: "TCA",
        countryName: "Turks and Caicos Islands"
    },
    {
        isoCode: "TUV",
        countryName: "Tuvalu"
    },
    {
        isoCode: "UGA",
        countryName: "Uganda"
    },
    {
        isoCode: "UKR",
        countryName: "Ukraine"
    },
    {
        isoCode: "ARE",
        countryName: "United Arab Emirates"
    },
    {
        isoCode: "GBR",
        countryName: "United Kingdom"
    },
    {
        isoCode: "USA",
        countryName: "United States"
    },
    {
        isoCode: "URY",
        countryName: "Uruguay"
    },
    {
        isoCode: "UZB",
        countryName: "Uzbekistan"
    },
    {
        isoCode: "VUT",
        countryName: "Vanuatu"
    },
    {
        isoCode: "VEN",
        countryName: "Venezuela, RB"
    },
    {
        isoCode: "VNM",
        countryName: "Vietnam"
    },
    {
        isoCode: "YEM",
        countryName: "Yemen, Rep."
    }, 
];
export var locationCurrency = function(isoCountry) {
    var currencies = {
        AFG: "AFN",
        ALB: "ALL",
        DZA: "DZD",
        ASM: "USD",
        AND: "EUR",
        AGO: "AOA",
        AIA: "XCD",
        ATG: "XCD",
        ARG: "ARS",
        ARM: "AMD",
        ABW: "AWG",
        AUS: "AUD",
        AUT: "EUR",
        AZE: "AZN",
        BHS: "BSD",
        BHR: "BHD",
        BGD: "BDT",
        BRB: "BBD",
        BLR: "BYR",
        BEL: "EUR",
        BLZ: "BZD",
        BEN: "XOF",
        BMU: "BMD",
        BTN: "INR",
        BOL: "BOB",
        BES: "USD",
        BIH: "BAM",
        BWA: "BWP",
        BVT: "NOK",
        BRA: "BRL",
        IOT: "USD",
        BRN: "BND",
        BGR: "BGN",
        BFA: "XOF",
        BDI: "BIF",
        KHM: "KHR",
        CMR: "XAF",
        CAN: "CAD",
        CPV: "CVE",
        CYM: "KYD",
        CAF: "XAF",
        TCD: "XAF",
        CHL: "CLP",
        CHN: "CNY",
        CXR: "AUD",
        CCK: "AUD",
        COL: "COP",
        COM: "KMF",
        COG: "XAF",
        COK: "NZD",
        CRI: "CRC",
        HRV: "HRK",
        CUB: "CUP",
        CUW: "ANG",
        CYP: "EUR",
        CZE: "CZK",
        CIV: "XOF",
        DNK: "DKK",
        DJI: "DJF",
        DMA: "XCD",
        DOM: "DOP",
        ECU: "USD",
        EGY: "EGP",
        SLV: "USD",
        GNQ: "XAF",
        ERI: "ERN",
        EST: "EUR",
        ETH: "ETB",
        FLK: "FKP",
        FRO: "DKK",
        FJI: "FJD",
        FIN: "EUR",
        FRA: "EUR",
        GUF: "EUR",
        PYF: "XPF",
        ATF: "EUR",
        GAB: "XAF",
        GMB: "GMD",
        GEO: "GEL",
        DEU: "EUR",
        GHA: "GHS",
        GIB: "GIP",
        GRC: "EUR",
        GRL: "DKK",
        GRD: "XCD",
        GLP: "EUR",
        GUM: "USD",
        GTM: "GTQ",
        GGY: "GBP",
        GIN: "GNF",
        GNB: "XOF",
        GUY: "GYD",
        HTI: "USD",
        HMD: "AUD",
        VAT: "EUR",
        HND: "HNL",
        HKG: "HKD",
        HUN: "HUF",
        ISL: "ISK",
        IND: "INR",
        IDN: "IDR",
        IRN: "IRR",
        IRQ: "IQD",
        IRL: "EUR",
        IMN: "GBP",
        ISR: "ILS",
        ITA: "EUR",
        JAM: "JMD",
        JPN: "JPY",
        JEY: "GBP",
        JOR: "JOD",
        KAZ: "KZT",
        KEN: "KES",
        KIR: "AUD",
        PRK: "KPW",
        KOR: "KRW",
        KWT: "KWD",
        KGZ: "KGS",
        LAO: "LAK",
        LVA: "EUR",
        LBN: "LBP",
        LSO: "ZAR",
        LBR: "LRD",
        LBY: "LYD",
        LIE: "CHF",
        LTU: "EUR",
        LUX: "EUR",
        MAC: "MOP",
        MKD: "MKD",
        MDG: "MGA",
        MWI: "MWK",
        MYS: "MYR",
        MDV: "MVR",
        MLI: "XOF",
        MLT: "EUR",
        MHL: "USD",
        MTQ: "EUR",
        MRT: "MRO",
        MUS: "MUR",
        MYT: "EUR",
        MEX: "MXN",
        FSM: "USD",
        MDA: "MDL",
        MCO: "EUR",
        MNG: "MNT",
        MNE: "EUR",
        MSR: "XCD",
        MAR: "MAD",
        MOZ: "MZN",
        MMR: "MMK",
        NAM: "ZAR",
        NRU: "AUD",
        NPL: "NPR",
        NLD: "EUR",
        NCL: "XPF",
        NZL: "NZD",
        NIC: "NIO",
        NER: "XOF",
        NGA: "NGN",
        NIU: "NZD",
        NFK: "AUD",
        MNP: "USD",
        NOR: "NOK",
        OMN: "OMR",
        PAK: "PKR",
        PLW: "USD",
        PAN: "USD",
        PNG: "PGK",
        PRY: "PYG",
        PER: "PEN",
        PHL: "PHP",
        PCN: "NZD",
        POL: "PLN",
        PRT: "EUR",
        PRI: "USD",
        QAT: "QAR",
        ROU: "RON",
        RUS: "RUB",
        RWA: "RWF",
        REU: "EUR",
        BLM: "EUR",
        SHN: "SHP",
        KNA: "XCD",
        LCA: "XCD",
        MAF: "EUR",
        SPM: "EUR",
        VCT: "XCD",
        WSM: "WST",
        SMR: "EUR",
        STP: "STD",
        SAU: "SAR",
        SEN: "XOF",
        SRB: "RSD",
        SYC: "SCR",
        SLE: "SLL",
        SGP: "SGD",
        SXM: "ANG",
        SVK: "EUR",
        SVN: "EUR",
        SLB: "SBD",
        SOM: "SOS",
        ZAF: "ZAR",
        SSD: "SSP",
        ESP: "EUR",
        LKA: "LKR",
        SDN: "SDG",
        SUR: "SRD",
        SJM: "NOK",
        SWZ: "SZL",
        SWE: "SEK",
        CHE: "CHF",
        SYR: "SYP",
        TWN: "TWD",
        TJK: "TJS",
        TZA: "TZS",
        THA: "THB",
        TLS: "USD",
        TGO: "XOF",
        TKL: "NZD",
        TON: "TOP",
        TTO: "TTD",
        TUN: "TND",
        TUR: "TRY",
        TKM: "TMT",
        TCA: "USD",
        TUV: "AUD",
        UGA: "UGX",
        UKR: "UAH",
        ARE: "AED",
        GBR: "GBP",
        USA: "USD",
        UMI: "USD",
        URY: "UYU",
        UZB: "UZS",
        VUT: "VUV",
        VEN: "VEF",
        VNM: "VND",
        VGB: "USD",
        VIR: "USD",
        WLF: "XPF",
        ESH: "MAD",
        YEM: "YER",
        ZMB: "ZMW",
        ZWE: "ZWL",
        ALA: "EUR"
    };
    if (isoCountry in currencies) {
        return currencies[isoCountry];
    }
    return "USD";
};
export var currencySymbol = function(currency) {
    var symbols = {
        AED: "د.إ",
        AFN: "؋",
        ALL: "L",
        ANG: "ƒ",
        AOA: "Kz",
        ARS: "$",
        AUD: "$",
        AWG: "ƒ",
        AZN: "₼",
        BAM: "KM",
        BBD: "$",
        BDT: "৳",
        BGN: "лв",
        BHD: ".د.ب",
        BIF: "FBu",
        BMD: "$",
        BND: "$",
        BOB: "Bs.",
        BRL: "R$",
        BSD: "$",
        BWP: "P",
        BYR: "p.",
        BZD: "BZ$",
        CAD: "$",
        CLP: "$",
        CNY: "\xa5",
        COP: "$",
        CRC: "₡",
        CUP: "₱",
        CVE: "$",
        CZK: "Kč",
        DJF: "Fdj",
        DKK: "kr",
        DOP: "RD$",
        DZD: "دج",
        EGP: "\xa3",
        ERN: "Nfk",
        ETB: "Br",
        EUR: "€",
        FJD: "$",
        FKP: "\xa3",
        GBP: "\xa3",
        GEL: "₾",
        GHS: "GH₵",
        GIP: "\xa3",
        GMD: "D",
        GNF: "FG",
        GTQ: "Q",
        GYD: "$",
        HKD: "$",
        HNL: "L",
        HRK: "kn",
        HUF: "Ft",
        IDR: "Rp",
        ILS: "₪",
        INR: "₹",
        IQD: "ع.د",
        IRR: "﷼",
        ISK: "kr",
        JMD: "J$",
        JPY: "\xa5",
        KES: "KSh",
        KGS: "лв",
        KHR: "៛",
        KMF: "CF",
        KPW: "₩",
        KRW: "₩",
        KYD: "$",
        KZT: "₸",
        LAK: "₭",
        LBP: "\xa3",
        LKR: "₨",
        LRD: "$",
        MAD: "MAD",
        MDL: "lei",
        MGA: "Ar",
        MKD: "ден",
        MMK: "K",
        MNT: "₮",
        MOP: "MOP$",
        MUR: "₨",
        MVR: "Rf",
        MWK: "MK",
        MXN: "$",
        MYR: "RM",
        MZN: "MT",
        NGN: "₦",
        NIO: "C$",
        NOK: "kr",
        NPR: "₨",
        NZD: "$",
        OMR: "﷼",
        PEN: "S/.",
        PGK: "K",
        PHP: "₱",
        PKR: "₨",
        PLN: "zł",
        PYG: "Gs",
        QAR: "﷼",
        RON: "lei",
        RSD: "Дин.",
        RUB: "₽",
        RWF: "R₣",
        SAR: "﷼",
        SBD: "$",
        SCR: "₨",
        SDG: "ج.س.",
        SEK: "kr",
        SHP: "\xa3",
        SLL: "Le",
        SOS: "S",
        SRD: "$",
        STD: "Db",
        SYP: "\xa3",
        SZL: "E",
        THB: "฿",
        TJS: "SM",
        TMT: "T",
        TND: "د.ت",
        TOP: "T$",
        TRY: "₺",
        TTD: "TT$",
        TWD: "NT$",
        TZS: "TSh",
        UAH: "₴",
        UGX: "USh",
        USD: "$",
        UYU: "$U",
        UZS: "лв",
        VEF: "Bs",
        VND: "₫",
        VUV: "VT",
        WST: "WS$",
        XAF: "FCFA",
        XCD: "$",
        XOF: "CFA",
        XPF: "₣",
        YER: "﷼",
        ZAR: "R",
        CHF: "CHF",
        SGD: "S$"
    };
    return symbols[currency] || "$";
};
