import React, { useEffect } from "react";
import { useCampaign } from "../Campaign/provider";
// Sets up the event tracking for pages
// For now only campaign event tracking which is stored in a provider and used
// to attach it to payments in the checkout process
export var EventsTracking = function(param) {
    var page = param.page;
    var ref;
    var setCampaign = useCampaign().setCampaign;
    var campaignEventTracking = (ref = page.events) === null || ref === void 0 ? void 0 : ref.find(function(event) {
        return event._type == "campaignEventTracking";
    });
    useEffect(function() {
        if (!setCampaign) return;
        if (!campaignEventTracking) return;
        setCampaign({
            campaign_organiser: campaignEventTracking.campaignOrganiser,
            campaign_category: campaignEventTracking.campaignCategory,
            campaign_name: campaignEventTracking.campaignName,
            data_sharing_disabled: campaignEventTracking.dataSharingDisabled
        });
    }, [
        setCampaign,
        campaignEventTracking
    ]);
    return null;
};
