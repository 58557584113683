import createImageUrlBuilder from "@sanity/image-url";
import { createPreviewSubscriptionHook } from "next-sanity";
import { publicConfig } from "./sanity.config";
export var urlFor = function(source) {
    return createImageUrlBuilder(publicConfig()).image(source);
};
export var imageBuilder = function(source) {
    return createImageUrlBuilder(publicConfig()).image(source);
};
export var usePreviewSubscription = createPreviewSubscriptionHook(publicConfig());
